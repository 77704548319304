/*
 * @Description:
 * @Version: 2.0
 * @Autor: libo
 * @Date: 2021-04-14 18:58:57
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:00
 */
import Cookies from 'js-cookie'

export default function(website) {
  const ParkKey = website.parkKey
  const TokenKey = website.tokenKey
  const RefreshTokenKey = website.refreshTokenKey
  const default_expires_in = 3600 * 2 * 1000
  const inFifteenMinutes = (time = default_expires_in) =>
    new Date(new Date().getTime() + time + 1000 * 300)
  return {
    /**
     * @description: 获取smartParkId
     */
    fn_parkKey__get() {
      return Cookies.get(ParkKey)
    },
    /**
     * @desc 设置smartParkId
     */
    fn_parkKey__set(token, time) {
      time = inFifteenMinutes(time)
      Cookies.set(ParkKey + '-Expires', time.getTime(), {
        expires: time
      })
      return Cookies.set(ParkKey, token, { expires: time })
    },
    /**
     * @description:删除smartParkId
     */
    fn_parkKey__remove() {
      Cookies.remove(ParkKey + '-Expires')
      return Cookies.remove(ParkKey)
    },
    /**
     * @desc 获取 TOKEN
     */
    fn_token__get() {
      return Cookies.get(TokenKey)
    },
    /**
     * @desc 获取 TOKEN 过期时间
     */
    fn_token__get_expires() {
      return Cookies.get(TokenKey + '-Expires') - 300000
    },
    /**
     * @desc 设置Token
     */
    fn_token__set(token, time) {
      time = inFifteenMinutes(time)
      Cookies.set(TokenKey + '-Expires', time.getTime(), {
        expires: time
      })
      return Cookies.set(TokenKey, token, { expires: time })
    },
    /**
     * @desc 删除Token
     */
    fn_token__remove() {
      Cookies.remove(TokenKey + '-Expires')
      return Cookies.remove(TokenKey)
    },
    /**
     * @desc 获取 refreshToken
     */
    fn_refreshToken__get() {
      return Cookies.get(RefreshTokenKey)
    },
    /**
     * @desc 设置 refreshToken
     */
    fn_refreshToken__set(refreshToken, time) {
      return Cookies.set(RefreshTokenKey, refreshToken, {
        expires: inFifteenMinutes(time)
      })
    },
    /**
     * @desc 删除 refreshToken
     */
    fn_refreshToken__remove() {
      return Cookies.remove(RefreshTokenKey)
    },
    /**
     * @desc 获取全部cookie
     */
    fn_cookie__all() {
      return Cookies.get()
    }
  }
}
