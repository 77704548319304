import { Object } from 'core-js'
import {
  ENVIRONMENT_DATA,
  DEVICE_DATA
} from '../../src/conf/smart-3d/const.js'
/**
 * @desc 获取字符长度 中文及日文算 2 个字符
 * @param {string} str
 */
export const fn_util__string_len = str => {
  // eslint-disable-next-line no-control-regex
  return str.replace(/[^\x00-\xff]/g, 'ab').length
}
/**
 * @desc 获取数据类型
 * @param {Any} val
 * @returns {String} [array|string|number|null|undefind|function|object|boolean]
 */
export const fn_util__data_type = val => {
  let type = Object.prototype.toString.call(val)
  type = /^\[object\s(.*)\]$/.exec(type)[1]
  return type.toLocaleLowerCase()
}
/**
 * @desc 过滤对象中的空数据
 * @param {Object} obj
 * @returns {Object}
 */
export const fn_util__filter_null = obj => {
  const res = {}
  for (const key in obj) {
    const value = obj[key]
    const emptyVal = ['null', null, undefined, 'undefined', '']
    !emptyVal.includes(value) && (res[key] = value)
  }
  return res
}

export const fn_util__filter_unique = obj => {
  const hash = {}
  const newBuildData = obj.reduce((item, next) => {
    hash[next.code] ? '' : (hash[next.code] = true && item.push(next))
    return item
  }, [])

  return newBuildData
}

/**
 * @desc 对象深克隆(真 -> 包括函数)
 * @param {Any} value
 * @param {Boolean} bool - 是否为真克隆
 * @returns {Any}
 */
export const fn_util__deep_clone = (value, bool = false) => {
  if (bool) {
    const type = fn_util__data_type(value)
    if (type === 'array') {
      return value.map(item => fn_util__deep_clone(item))
    } else if (type === 'object') {
      const res = {}
      for (const key in value) {
        res[key] = fn_util__deep_clone(value[key])
      }
      return res
    }
    return value
  } else {
    return JSON.parse(JSON.stringify(value))
  }
}

export const fn_util_deep_Clone_other = target => {
  // 定义一个变量
  let result
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = [] // 将result赋值为一个数组，并且执行遍历
      for (const i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]))
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {}
      for (const i in target) {
        result[i] = deepClone(target[i])
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target
  }
  // 返回最终结果
  return result
}
/**
 * @desc 函数防抖（debounce）：当持续触发事件时，一定时间段内没有再触发事件，事件处理函数才会执行一次，如果设定的时间到来之前，又一次触发了事件，就重新开始延时
 * @param {Function} handle 事件
 * @param {Object} context 事件执行环境
 * @param {Number} [time=300] 触发时间间隔
 * @returns {Function}
 */
export const fn_util_debounce = (handle, context, time = 300) => {
  let debounce
  const handleThings = function() {
    handle.apply(context, arguments)
    debounce = undefined
  }
  return function() {
    debounce && clearTimeout(debounce)
    debounce = setTimeout(handleThings, time)
  }
}
/**
 * @desc 函数节流（throttle）：当持续触发事件时，保证一定时间段内只调用一次事件处理函数。
 * @param {Function} handle 事件
 * @param {Object} context 事件执行环境
 * @param {Number} [time=300] 触发时间间隔
 * @returns {Function}
 */
export const fn_util_throttle = (handle, context, time = 300) => {
  let throttle
  const handleThings = function() {
    handle.apply(context, arguments)
    throttle = undefined
  }
  return function() {
    if (throttle) {
      throttle = setTimeout(handleThings, time)
    }
  }
}

/**
 * @desc 获取 DOM 距离视窗边界的距离
 * @param {String} select css选择器
 * @returns {Object} obj
 * @returns {Number} obj.top
 * @returns {Number} obj.right
 * @returns {Number} obj.bottom
 * @returns {Number} obj.left
 */
export const fn_util_get_clientRect = select => {
  let dom = select
  if (typeof select === 'string') {
    dom = document.querySelector(select)
  }
  const clientRect = dom.getBoundingClientRect()
  const [clientWidth, clientHeight] = [
    document.body.clientHeight,
    document.body.clientWidth
  ]
  return {
    top: clientRect.top,
    right: clientRect.right,
    bottom: clientRect.bottom,
    left: clientRect.left
  }
}
/**
 * @desc 通过属性值在多维对象数组中筛选目标对象(目标对象的祖先链未知)
 * @param {Object|Array} data 数据源
 * @param {String|Number} value 属性值
 * @param {String} prop 属性
 * @param {String} children 下级数据源标识
 * @param {Array} [ancestors] 目标对象的祖先链
 * @returns {Array|null} ancestors
 */
export const fn_util__find_deep = function f(
  data,
  value,
  prop,
  children,
  ancestors = []
) {
  const type = fn_util__data_type(value)
  if (type === 'array') {
    const res = []
    value.forEach(item => {
      const x = f(data, item, prop, children, (ancestors = []))
      x && res.push(x)
    })
    return res
  } else {
    if (!value) {
      return null
    }
    if (Object.prototype.toString.call(data) === '[object Array]') {
      const res = data.find(child => f(child, value, prop, children, ancestors))
      if (res) {
        ancestors.unshift(res)
        return ancestors
      } else {
        return null
      }
    } else if (value === data[prop]) {
      return true
    } else if (data[children] && data[children].length) {
      return f(data[children], value, prop, children, ancestors)
    }
    return null
  }
}

/**
 * @desc 通过祖先链在多维对象数组中筛选目标对象(目标对象的祖先链已知)
 * @param {Object|Array} data 数据源
 * @param {Array|String} ancestors 目标对象的祖先链
 * @param {String} prop 属性
 * @param {String} children 下级数据源标识
 * @returns {Array|null} ancestors
 */
export const fn_util__find_to_deep = function f(
  data,
  ancestors,
  prop,
  children
) {
  if (!ancestors || ancestors.length === 0) {
    return null
  }
  if (typeof ancestors === 'string') {
    ancestors = ancestors.split()
  }
  let res = data
  const temp_ancestors = []
  if (Object.prototype.toString.call(res) === '[object Object]') {
    res = [res]
  }
  for (const value of ancestors) {
    const temp_res = res.find(item => item[prop] === value)
    if (!temp_res) {
      return false
    }
    temp_ancestors.push(temp_res)
    res = temp_res[children]
  }
  return temp_ancestors
}

/**
 * 构造树型结构数据（ruoyi）
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree(data, id, parentId, children, rootId) {
  id = id || 'id'
  parentId = parentId || 'parentId'
  children = children || 'children'
  rootId = rootId || 0
  // 对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data))
  // 循环所有项
  const treeData = cloneData.filter(father => {
    const branchArr = cloneData.filter(child => {
      // 返回每一项的子级数组
      return father[id] === child[parentId]
    })
    branchArr.length > 0 ? (father.children = branchArr) : ''
    // 返回第一层
    return father[parentId] === rootId
  })
  return treeData != '' ? treeData : data
}

// /**
//  * @desc element-ui 表格数数据构建
//  */
// export const fn_util_create_element_table_tree = (data, key, parentKey) => {
//   let [parent, children] = [[], []];
//   for (let i = data.length - 1; i > 0; i--) {
//     let node = data[i];
//     let prev_node = data[i - 1];
//     if (node[parentKey] === prev_node[key]) {
//       // 上一个节点是当前节点的父节点
//       if (
//         Object.prototype.toString.call(prev_node.children) !== '[object Array]'
//       ) {
//         prev_node.children = [];
//       }
//       prev_node.children.unshift(node);
//     } else if (node[key] === prev_node[parentKey]) {
//       // 上一个节点是当前节点的子节点
//       if (Object.prototype.toString.call(node.children) !== '[object Array]') {
//         node.children = [];
//       }
//       node.children.unshift(data[i - 1]);
//     } else {
//       // 上一个节点与当前节点不是父子关系
//       let next_data = data.slice(-2);
//       for (let n = next_data.length - 1; n >= 0; n--) {
//         let _node = next_data[n];
//         if (node[parentKey] === _node[key]) {
//           // 其他节点是当前节点的父节点
//           if (
//             Object.prototype.toString.call(prev_node.children) !==
//             '[object Array]'
//           ) {
//             _node.children = [];
//           }
//           _node.children.unshift(node);
//         } else if (node[key] === _node[parentKey]) {
//           // 其他节点是当前节点的子节点
//           if (
//             Object.prototype.toString.call(node.children) !== '[object Array]'
//           ) {
//             node.children = [];
//           }
//         }
//       }
//     }
//   }
//   for (let [node, index] of data) {
//     let parent = null,
//       children = [],
//       siblings = [];
//     node[parentKey]; // 父级key
//     data[index + 1][parentKey];

//     if (node[parentKey] === data[index + 1][key]) {
//       // 下一个节点是当前节点的父节点
//       parent = data[index + 1];
//       parent.children = children;
//     } else if (node[parentKey] === data[index + 1][parentKey]) {
//       // 下一个节点是当前节点的兄弟节点
//       siblings.push(data[index + 1]);
//     } else if (node[key] === data[index + 1][parentKey]) {
//       // 下一个节点是当前节点的子节点
//       children.push(data[index + 1]);
//     } else {
//       // 下一个节点与当前节点无直接关系
//     }
//   }
// };
export const fn_unil__localDown = () => {}
export const fn_unil__loadScript = async url => {
  if (window.ace) return
  const loadScript = function(params) {
    return new Promise(resolve => {
      const _script = document.createElement('script')
      _script.src = params
      _script.type = 'text/javascript'
      resolve(_script)
    })
  }
  let f = document.createDocumentFragment()
  let children = null
  if (typeof url === 'string') {
    f = await loadScript(url)
  } else {
    children = await Promise.all(url.map(item => loadScript(item)))
    children.forEach(item => f.appendChild(item))
  }
  // console.log(f);
  document.body.appendChild(f)
}
/** 颜色临近值计算 参考：https://blog.csdn.net/isea533/article/details/85860078 */
const fn_util__color = (function() {
  // RGB 转 HSL 类型
  function rgb2hsl({ R, G, B }) {
    let H, S, L
    const var_R = R / 255
    const var_G = G / 255
    const var_B = B / 255

    const var_Min = Math.min(var_R, var_G, var_B) // Min. value of RGB
    const var_Max = Math.max(var_R, var_G, var_B) // Max. value of RGB
    const del_Max = var_Max - var_Min // Delta RGB value

    L = (var_Max + var_Min) / 2

    if (del_Max == 0) {
      // This is a gray, no chroma...
      H = 0
      S = 0
    } // Chromatic data...
    else {
      if (L < 0.5) S = del_Max / (var_Max + var_Min)
      else S = del_Max / (2 - var_Max - var_Min)

      const del_R = ((var_Max - var_R) / 6 + del_Max / 2) / del_Max
      const del_G = ((var_Max - var_G) / 6 + del_Max / 2) / del_Max
      const del_B = ((var_Max - var_B) / 6 + del_Max / 2) / del_Max

      if (var_R == var_Max) H = del_B - del_G
      else if (var_G == var_Max) H = 1 / 3 + del_R - del_B
      else if (var_B == var_Max) H = 2 / 3 + del_G - del_R

      if (H < 0) H += 1
      if (H > 1) H -= 1
    }
    return {
      H,
      S,
      L
    }
  }
  // HSL 转 RGB 类型的辅助函数
  function Hue_2_RGB(v1, v2, vH) {
    if (vH < 0) vH += 1
    if (vH > 1) vH -= 1
    if (6 * vH < 1) return v1 + (v2 - v1) * 6 * vH
    if (2 * vH < 1) return v2
    if (3 * vH < 2) return v1 + (v2 - v1) * (2 / 3 - vH) * 6
    return v1
  }
  // HSL 转 RGB 类型
  function hsl2rgb({ H, S, L }) {
    let R, G, B
    if (S == 0) {
      R = L * 255
      G = L * 255
      B = L * 255
    } else {
      let var_2, var_1
      if (L < 0.5) var_2 = L * (1 + S)
      else var_2 = L + S - S * L

      var_1 = 2 * L - var_2

      R = Math.round(255 * Hue_2_RGB(var_1, var_2, H + 1 / 3))
      G = Math.round(255 * Hue_2_RGB(var_1, var_2, H))
      B = Math.round(255 * Hue_2_RGB(var_1, var_2, H - 1 / 3))
    }
    return {
      R,
      G,
      B
    }
  }
  // HEX 转 RGB 类型
  function hex2rgb(hex) {
    if (hex.indexOf('#') != -1) {
      hex = hex.substr(1, 6)
    }
    const R = parseInt(hex.substr(0, 2), 16)
    const G = parseInt(hex.substr(2, 2), 16)
    const B = parseInt(hex.substr(4, 2), 16)
    return {
      R,
      G,
      B
    }
  }
  // RGB 转 HEX 类型的辅助函数
  function dec2hex(dec) {
    return (dec + 0x100)
      .toString(16)
      .substr(1, 2)
      .toUpperCase()
  }
  // RGB 转 HEX 类型
  function rgb2hex({ R, G, B }) {
    return '#' + dec2hex(R) + dec2hex(G) + dec2hex(B)
  }

  return {
    rgb2hsl,
    hsl2rgb,
    hex2rgb,
    rgb2hex
  }
})()
/**
 * @desc 通过修改HSL的色调(H)、饱和度(S)、明度(L)更改颜色的值
 * 色调：颜色的底色调。这个值在0到360之间，表示色轮的角度。
   饱和度：饱和度是多少？这需要一个从0-100%的值，其中0是没有颜色（它将显示为灰色），100%是全彩色饱和度。
   明度：颜色有多亮或明亮？这需要一个从0-100%的值，其中0是无光（它会出现全黑的），100%是充满光的（它会出现全白）
 * @param {string} hex 颜色值类型为hex
 * @param {string} type 改变的类型（H,S,L）
 * @param {*} value 在当前值的基础上加上的值
 */
export const fn_util__calc_color = (hex, type, value) => {
  // 获得当前颜色的 HSL 值
  const hsl = fn_util__color.rgb2hsl(fn_util__color.hex2rgb(hex))
  hsl[type] = hsl[type] + value
  return fn_util__color.rgb2hex(fn_util__color.hsl2rgb(hsl))
}
export const fn_util__setStyle = value => {
  const node = document.createElement('style')
  node.type = 'text/css'
  if (node.styleSheet) {
    // ie下
    node.styleSheet.cssText = value
  } else {
    node.innerHTML = value // 或者写成 node.appendChild(document.createTextNode(str))
  }
  document.getElementsByTagName('head')[0].appendChild(node)
}
export const fn_util__date_format = (value = new Date()) => {
  let date = new Date(value)
  date === 'Invalid Date' && (date = new Date())
  if (date !== 'Invalid Date') {
    const yy = date.getFullYear() // year
    let MM = date.getMonth() + 1 // month
    let dd = date.getDate() // day
    let hh = date.getHours() // hour
    let mm = date.getMinutes() // minute
    let ss = date.getSeconds() // second
    const timestamp = date.getTime() // 时间搓
    let day = date.getDay() // 周几
    MM = MM > 9 ? MM : '0' + MM
    dd = dd > 9 ? dd : '0' + dd
    hh = hh > 9 ? hh : '0' + hh
    mm = mm > 9 ? mm : '0' + mm
    ss = ss > 9 ? ss : '0' + ss
    day = +day === 0 ? 7 : day
    const dayToUpperCase = ['一', '二', '三', '四', '五', '六', '日']
    return {
      yy,
      MM,
      dd,
      hh,
      mm,
      ss,
      timestamp,
      day,
      dayToUpperCase: dayToUpperCase[day - 1]
    }
  }
}
export const fn_util__date_format_other = (value = new Date()) => {
  let date = new Date(value)
  date === 'Invalid Date' && (date = new Date())
  if (date !== 'Invalid Date') {
    const yy = date.getFullYear() // year
    const MM = date.getMonth() + 1 // month
    const dd = date.getDate() // day
    const hh = date.getHours() // hour
    const mm = date.getMinutes() // minute
    const ss = date.getSeconds() // second
    const timestamp = date.getTime() // 时间搓
    let day = date.getDay() // 周几
    day = +day === 0 ? 7 : day
    const dayToUpperCase = ['一', '二', '三', '四', '五', '六', '日']
    return {
      yy,
      MM,
      dd,
      hh,
      mm,
      ss,
      timestamp,
      day,
      dayToUpperCase: dayToUpperCase[day - 1]
    }
  } else {
    return value
  }
}

export const fn_rgb_color_gradient = (value, num) => {
  // 16进制颜色值的正则
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // 把颜色值变成小写
  let color = value.toLowerCase()
  if (reg.test(color)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (color.length === 4) {
      let colorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1))
      }
      color = colorNew
    }
    // 处理六位的颜色值，转为RGB
    const colorChange = []
    for (let j = 1; j < 7; j += 2) {
      colorChange.push(parseInt('0x' + color.slice(j, j + 2)))
    }
    return 'rgb(' + colorChange.join(',') + ',' + num + ')'
  } else {
    return color
  }
}

/**
 * @desc 带水印图片
 * @params {Object} file 图片文件
 * @params {Object} options 配置项
 * */
export const fn_util__canvasImage = (file, options, Canvas = null) => {
  let params = {
    base64: true,
    accept: 'image/jpeg',
    maxWidth: 0,
    maxHeight: 0
  }
  params = options
    ? {
      ...params,
      ...options
    }
    : params
  return new Promise((resolve, reject) => {
    if (!file) reject('file error')
    const reader = new FileReader()
    const img = new Image()
    reader.readAsDataURL(file)
    reader.onload = function(e) {
      img.src = e.target.result
    }
    img.onload = function() {
      let originWidth = 0
      let originHeight = 0

      params.maxWidth = params.maxWidth || this.width
      params.maxHeight = params.maxHeight || this.height
      originWidth = Math.min(params.maxWidth, this.width)
      originHeight = Math.min(params.maxHeight, this.height)

      const widthScale = originWidth / this.width
      const heightScale = originHeight / this.height
      const originScale = Math.min(widthScale, heightScale)
      if (originScale <= 1) {
        originWidth = this.width * originScale
        originHeight = this.height * originScale
      }
      Canvas = Canvas || document.createElement('canvas')
      Canvas.width = originWidth
      Canvas.height = originHeight
      const ctx = Canvas.getContext('2d')
      console.log(Canvas)
      ctx.drawImage(img, 0, 0, originWidth, originHeight)
      resolve(
        params.base64 ? Canvas.toDataURL(params.accept, params.quality) : Canvas
      )
    }
  })
}
/**
 * @description: 获取环境描述
 * @param {*} environment 环境数据
 * @return {*}
 * @author: libo
 */
export const fn_util_get_environmentInfo = (environment = null) => {
  let { id, value } = environment
  const eData = EnvironmentData
  const index = eData.findIndex(item => item.id === id)

  if (index < 0) return ''
  const infoList = eData[index].value
  infoList.map(item => {
    if (value >= item.min && value <= item.max) {
      value = ' (' + item.val + ')'
    }
  })
  return value
}

/**
 * @description: 页数计算
 * @param {*}
 * @return {*}
 * @author: hs
 */
export const fn_util_getTotalPageNum = (pageTotal, pageSize) => {
  return parseInt((pageTotal + pageSize - 1) / pageSize)
}

/**
 * @description: 环境信息 等级划分
 * @param {*}
 * @return {*}
 * @author: hs
 */
export const fn_util_formatEnvironment = (value, name) => {
  // console.log(ENVIRONMENT_DATA);//参照源
  const list = ENVIRONMENT_DATA[name] || []
  const result = list.find(item => {
    return Number(value) <= Number(item.value)
  })
  if (typeof result === 'object') {
    result.val = value || '' // 传入的数值
  }
  return result
}

/**
 * @description: 设备信息 过滤
 * @param {*}
 * @return {*}
 * @author: hs
 */
export const fn_util_formatDeviceData = (key, name, value) => {
  // console.log(DEVICE_DATA);//参照源
  const list = DEVICE_DATA
  let deviceInfo
  for (let i = 0; i < list.length; i++) {
    if (list[i].productKey == key) {
      deviceInfo = list[i].params.find(item => item.identity == name)
    }
  }
  if (!deviceInfo) {
    return {
      identity: '',
      name: '',
      unit: '',
      computed: null
    }
  }
  if (deviceInfo.computed) {
    deviceInfo['value'] = deviceInfo.computed(value)
  }
  return deviceInfo
}
